.content img {
  max-width: 90%;
  height: auto;
  margin-left: 5%;
  object-fit: scale-down;
}

.content .text-center {
  text-align: center;
}

.content h2 {
  font-size: 2.5rem;
  font-weight: 700;
}
.content h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.content h5 {
  font-size: 1.5rem;
  font-weight: 400;
}

.content p,
.content ul,
.content li {
  line-height: 30px;
}
.content .vertical-center {
  height: 100%;
  display: flex;
  align-items: center;
}
.content .vertical-bottom {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.content .cta-subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  color: '#031D5B';
  background-image: url('/images/sub-accent.svg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-bottom: 1px;
}

.content .vertical-max-150 {
  height: 150px;
  display: flex;
  align-items: center;
}

.content .vertical-max-150 img {
  max-height: 150px;
}

.content ul {
  padding-inline-start: 25px;
}
